import { getMethod } from "hooks/getHook";
import { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { socket } from "socket";
import { getBilling } from "./home";

export const AuthContext = createContext();
const getNotification = () => {
  if ("Notification" in window) {
    return Notification.permission;
  } else {
    return null;
  }
};

const checkNotificationExit = () => {
  return "Notification" in window;
};

const ensureSocketConnection = (callback) => {
  if (!socket.connected) {
    socket.connect();
    socket.once("connect", () => {
      callback();
    });
  } else {
    callback();
  }
};

export function PayrollProvider({ children, isAuthRequireedPages }) {
  const [user, setUser] = useState({});
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [emergencyData, setEmergencyData] = useState({});
  const [nextofkin, setNextofkin] = useState({});
  const [chatNotification, setChatNotification] = useState({});
  const [showMsg, setShowMsg] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [isTypingGroup, setIsTypingGroup] = useState({});
  const [isTyping, setIsTyping] = useState({});
  const [chats, setChats] = useState([]);
  const [params, setParams] = useState({});
  const [departments, setDepartments] = useState([]);
  const { data: billing } = useQuery(["current-billing"], getBilling, {
    enabled: isAuthRequireedPages,
  });

  const [notification, setNotification] = useState(null);
  const [groupNotification, setGroupNotification] = useState(null);
  const {
    data: employees,
    refetch: refetchEmployees,
    isLoading: isLoadingEmployee,
  } = useQuery(
    [
      "employees",
      {
        route: `/employees/${user?.id}/employee_filter_myedge`,
        // route: "/employees/filter_myedge/",
        params: params,
      },
    ],
    getMethod,
    {
      enabled: Boolean(user?.id) && isAuthRequireedPages,
    }
  );

  useQuery(
    [
      "get-departments",
      {
        route: "/departments/",
        params: {},
      },
    ],
    getMethod,
    {
      enabled: isAuthRequireedPages,
      onSuccess: (data) => {
        setDepartments(data?.results);
      },
    }
  );
  const chatQuery = useQuery(
    [
      "get-chats",
      {
        route: `/chat_rooms/get_employee_individual_chat_n_group_rooms/${user?.id}/`,
        params: {},
      },
    ],
    getMethod,

    {
      enabled: Boolean(user?.id) && isAuthRequireedPages,
      onSuccess: (data) => {
        setChats(data);
      },
    }
  );

  const [notificationPermission, setNotificationPermission] =
    useState(getNotification);

  const domain = localStorage.getItem("business_id");

  // const [setMessageCount] = useState(0);

  useEffect(() => {
    if (!isAuthRequireedPages) return;
    if (checkNotificationExit() && notificationPermission !== "granted") {
      Notification.requestPermission().then((permission) => {
        setNotificationPermission(permission);
      });
    }
  }, [notificationPermission, isAuthRequireedPages]);

  useEffect(() => {
    if (!isAuthRequireedPages) return;

    const handleChatNotification = (data) => {
      chatQuery.refetch();
      if (data?.chat_room) {
        moveChatWithRecentMessageToTop(data.chat_room);
      }
      setNotification(data);
    };

    ensureSocketConnection(() => {
      socket.on(
        `chat-notification-${domain}-${user?.id}`,
        handleChatNotification
      );
    });

    return () => {
      socket.off(
        `chat-notification-${domain}-${user?.id}`,
        handleChatNotification
      );
    };
  }, [domain, user?.id, isAuthRequireedPages]);

  useEffect(() => {
    if (!isAuthRequireedPages) return;

    const handleGroupNotification = (data) => {
      chatQuery.refetch();
      moveChatWithRecentMessageToTop(data.group);
      setGroupNotification(data);
    };

    ensureSocketConnection(() => {
      socket.on(`group-notification-${domain}`, handleGroupNotification);
    });

    return () => {
      socket.off(`group-notification-${domain}`, handleGroupNotification);
    };
  }, [domain, isAuthRequireedPages]);

  // Update online employees handler
  useEffect(() => {
    if (!isAuthRequireedPages || !user?.id) return;

    ensureSocketConnection(() => {
      socket.emit("online-employees", {
        employee_id: user.id,
        business_id: domain,
      });
    });

    return () => {
      socket.off("offline-employees");
    };
  }, [domain, user?.id, isAuthRequireedPages]);

  useEffect(() => {
    if (!isAuthRequireedPages) return;

    const handleOnlineUsers = (data) => {
      const onlineUserIds = Object.values(data).map((user) =>
        Number(user.split("|")[0])
      );

      setOnlineUsers((prevUsers) => {
        // Only update state if the new array is different to prevent unnecessary renders
        if (JSON.stringify(prevUsers) !== JSON.stringify(onlineUserIds)) {
          return onlineUserIds;
        }
        return prevUsers;
      });
    };

    socket.on(`online-${domain}`, handleOnlineUsers);

    return () => {
      socket.off(`online-${domain}`, handleOnlineUsers);
    };
  }, [domain, isAuthRequireedPages]);

  // Typing indicators
  useEffect(() => {
    if (!isAuthRequireedPages || !user?.id) {
      socket.off(`typing-${domain}-${user.id}`);
      return;
    }

    const handleTyping = (data) => {
      if (data !== isTyping) {
        setIsTyping(data);
      }
    };

    ensureSocketConnection(() => {
      socket.on(`typing-${domain}-${user.id}`, handleTyping);
    });

    return () => {
      socket.off(`typing-${domain}-${user.id}`, handleTyping);
    };
  }, [domain, isAuthRequireedPages, user?.id, isTyping]);

  // Group typing indicator
  useEffect(() => {
    if (!isAuthRequireedPages || !user?.id) {
      socket.off(`typing-${domain}`);
      return;
    }

    const handleTyping = (data) => {
      if (data !== isTypingGroup) {
        setIsTypingGroup(data);
      }
    };

    ensureSocketConnection(() => {
      socket.on(`typing-${domain}`, handleTyping);
    });

    return () => {
      socket.off(`typing-${domain}`, handleTyping);
    };
  }, [domain, isAuthRequireedPages, user?.id, isTypingGroup]);

  const moveChatWithRecentMessageToTop = (chatId) => {
    if (!chatId) return;

    setChats((prevChats) => {
      const chatIndex = prevChats.findIndex((chat) => chat.id === chatId);
      if (chatIndex === -1) return prevChats;

      const updatedChats = [...prevChats];
      const [chat] = updatedChats.splice(chatIndex, 1);
      return [chat, ...updatedChats];
    });
  };

  const context = {
    user,
    setUser,
    successMsg,
    setSuccessMsg,
    errorMsg,
    setErrorMsg,
    emergencyData,
    setEmergencyData,
    nextofkin,
    setNextofkin,
    showMsg,
    setShowMsg,
    chatNotification,
    setChatNotification,
    onlineUsers,
    isTypingGroup,
    isTyping,
    chats,
    chatQuery,
    employees,
    refetchEmployees,
    params,
    setParams,
    notification,
    groupNotification,
    setNotification,
    setGroupNotification,
    notificationPermission,
    isLoadingEmployee,
    departments,
    billing,
    moveChatWithRecentMessageToTop,
    ensureSocketConnection,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext) ?? {};
